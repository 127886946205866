import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import pageStyles from '../styles/page-common.module.css';
import formStyles from '../styles/form-common.module.css';
import { createUser, getCheckoutSessionURL } from '../backend';


const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Get the navigate function
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const isLoggedIn = useSelector((state) => state.user).isLoggedIn;

  // shouldn't be here if logged in; navigate to home
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [navigate, isLoggedIn]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Username validation: alphanumeric and < 30 characters
  const isUsernameValid = (username) => {
    const usernameRegex = /^[a-zA-Z0-9]+$/; // only letters and numbers
    return username.length <= 30 && usernameRegex.test(username);
  };

  // Password validation: min 8 chars, mix of letters, numbers, special chars
  const isPasswordStrong = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    return passwordRegex.test(password);
  };

  const handleRegistration = async (e) => {
    e.preventDefault();

    // check if passwords match
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    // validate username
    if (!isUsernameValid(formData.username)) {
      setErrorMessage('Username must be alphanumeric and less than 30 characters');
      return;
    }

    // validate password strength
    if (!isPasswordStrong(formData.password)) {
      setErrorMessage('Password must be at least 8 characters long, with uppercase, lowercase, numbers, and special characters.');
      return;
    }

    setLoading(true);

    // Send registration data to the server (backend)
    try {
      setErrorMessage('');
      const response = await createUser(formData)
      if(response.status === 201) {
        const checkoutURL = await getCheckoutSessionURL(formData['username'])
        window.location.replace(checkoutURL);
        // navigate('/registration-success', { state: { username: formData.username } });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage('Username or email already in use. Please choose a different one.');
        setLoading(false);
      } else {
        console.log(error);
        setErrorMessage('Something went wrong.');
        setLoading(false);
      }
    }
  };

  return (
    <div className={pageStyles.styledPage}>
      <h2>Register</h2>
      <form onSubmit={handleRegistration} className={formStyles.styledForm}>
        <label>
          Username:
          <input
            type="text"
            name="username"
            placeholder="Username"
            value={formData.username}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Email:
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            name="password"
            placeholder="Password" 
            value={formData.password}
            onChange={handleInputChange}
            required
          />
        </label>
        <label>
          Confirm Password:
          <input
            type="password"
            name="confirmPassword"
            placeholder="Password" 
            value={formData.confirmPassword}
            onChange={handleInputChange}
            required
          />
        </label>
        <button type="submit" disabled={loading}>
          {loading ? 'Registering...' : 'Register'}
        </button>
      </form>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
    </div>
  );
};

export default Register;